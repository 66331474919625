@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,700&family=Inter:wght@400;500;600;700&display=swap");

* {
  font-style: normal;
}

section ol {
  --offset: 60px !important;
  --width: 50% !important;
  display: flex;
  justify-content: flex-end;
}

[data-sonner-toaster][data-x-position="left"] {
  right: 200px !important;
}
